<template>
    <div class="pa-0 ma-0">
    <v-form  v-on:submit.prevent ref="form"
    v-model="valid"
    lazy-validation >
        <v-text-field
            class="ma-auto pa-auto mb-3 transparent font-weight-light elevation-0" 
            content-class="pa-auto ma-auto"
            v-model="role.name"
            :rules="[rules.required]"
            :label="$t('title')"
            :placeholder="$t('title')"
            outlined
            dense
            hide-details
            clearable>
            <template  v-slot:append-outer>
                <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="add()" class="ma-auto pa-auto custom-color-accent-text" v-on='on' v-bind="attrs" icon>
                    <v-icon>mdi-briefcase-plus</v-icon>
                    </v-btn>
                </template>
                <span>{{$t("add")}}</span>
                </v-tooltip>   
            </template>
        </v-text-field>
    </v-form>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default({
   data() {
    return {
        valid: true,
        rules: {
            required: value => !!value || this.$t('required'),
        },
        role:{},
    }},
   methods:{
       add(){
        if(this.$refs.form.validate()){
          this.newRole(this.role).then(()=>{
              this.$root.$emit("callAlert", {text:"Role Added Successfuly", type:"success", alert:true});
              this.$emit("hideAdd");
           },()=>{this.$root.$emit("callAlert", {text:this.$t("Something went wrong"), type:"warning", alert:true});});
        }
        else
          return
           
       },
       ...mapActions("role", ["newRole"])

   }
})
</script>
<style scoped>

</style>
