<template>
  <div class="my-border ma-0 pa-0" v-if="roles.length>0">
    <div class="elevation-0 pa-0 ma-0 white" style="min-height:calc(100vh - 130px)">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="pa-3 ma-0">
          <all-roles :roles="roles" @setRole='setR'></all-roles>
        </v-col>
        <v-col v-if="role!=null" cols="12" class="pa-3 ma-0">
          <permissions :selectedRole="role" :permissions="permissions"></permissions>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import allRoles from "../components/roles/AllRoles";
import permissions from "../components/roles/Permissions";
import { mapActions, mapState} from "vuex";
export default {
  components: {
    allRoles,
    permissions,
  },
  data() {
  return {
  }},
  async created() {
   await this.retriveRoles();
   await this.retrivePermissions();
  },
  methods: {
    ...mapActions("role", ["setRole","retriveRoles","retrivePermissions"]),
    setR(role){
      this.setRole(role);
    }
  },
   
  computed: {
    ...mapState("role", {
      roles: "roles",
      permissions:"permissions",
      role:"role"
     }),

  }
};
</script>
<style scoped>
</style>