<template>
  <v-card
    class="pa-0 ma-0 elevation-0 white"
    style="max-height: 100%"
  >
    <v-card-title class="py-0 my-0 my-border customlightgray">
      <v-row
        class="ma-0 pa-0 justify-center text-center text-md-left text-lg-left">
        <v-col cols="12" class="px-0 ma-0">
          <div class="font-weight-light pa-0 ma-0">
            <v-autocomplete
                class="ma-auto pa-auto transparent font-weight-light elevation-0"
                content-class="pa-auto ma-auto"
                :items="roles"
                v-model="selectedRole"
                item-value="id"
                :placeholder="$t('Select Role')"
                :label="$t('Select Role')"
                outlined
                dense
                hide-details
                return-object>
                <template v-slot:append-outer>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="expand=!expand" class="pa-auto ma-auto custom-color-accent-text" v-on='on' v-bind="attrs" icon>
                        <v-icon>mdi-plus-box</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t("add")}}</span>
                  </v-tooltip>   
                </template>
                <template slot="selection" slot-scope="data">
                     <span class="text-capitalize">{{ data.item.name}}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <v-row class="pa-0 ma-0">
                      <span class="text-capitalize">{{ data.item.name}}</span>
                      <v-spacer></v-spacer>
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-if=" data.item.name!='Admin'" class="justify-right" @click.stop="deleteR(data.item)" v-on='on' v-bind="attrs" icon>
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t("delete")}}</span>
                      </v-tooltip>
                  </v-row>
                </template>

            </v-autocomplete>
          </div>
        </v-col>
        <v-expand-transition>
                <v-col cols="12" v-show="expand" class="pa-0 ma-0 "> 
                   <addNewRole @hideAdd="expand=false"></addNewRole>
                </v-col>
        </v-expand-transition>
      </v-row>
    </v-card-title>
    <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>{{$t("delete")}}</v-card-title>
          <v-card-text>{{$t("Are you sure you want to delete this item?")}}</v-card-text>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="removeRole()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-card>
</template>
<script>
import addNewRole from '../roles/AddNewRole'
import { mapActions } from "vuex";

export default {
    props: ["roles"],
     components: {
          addNewRole,
    },
    data() {
    return {
        expand:false,
        dialogDelete:false,
        selectedRole:null,
        deletedItem:{}
    }},
    created(){
      this.$emit("setRole", this.selectedRole);
    },
     methods:{
       deleteR(item){
          this.deletedItem = item;
          this.dialogDelete = true;
       },
       removeRole(){
          this.dialogDelete = false;
          this.deleteRole(this.deletedItem.id).then(()=>{
            let color="dark";
            let alert="itemRemovedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          })
       },
       ...mapActions("role", ["deleteRole"])
   },
   watch:{
     selectedRole(val){
       this.$emit("setRole", val);
     }
   }
};
</script>
<style>
</style>
