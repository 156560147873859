<template>
  <v-card class="pa-0 ma-0 elevation-0 white" style="max-height: 100%">
    <v-row>
      <v-col>
        <v-card-text>
          <v-treeview
            v-model="selected"
            :items="items"
            item-key="name"
            selected-color="indigo"
            open-on-click
            :selectable="selectedRole.name!='Admin'"
            return-object
            expand-icon="mdi-chevron-down"
            :on-icon="isLoading?'mdi-progress-clock':'mdi-bookmark'"
            :off-icon="isLoading?'mdi-progress-clock':'mdi-bookmark-outline'"
            :indeterminate-icon="isLoading?'mdi-progress-clock':'mdi-bookmark-minus'"
          ></v-treeview>
        </v-card-text>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="12" md="6">
        <v-card-text>
          <div
            v-if="selected.length === 0"
            key="title"
            class="title font-weight-light grey--text pa-4 text-center"
          >Select permissions</div>

          <v-scroll-x-transition  v-if="selectedRole.name!='Admin'" group hide-on-leave>
            <v-chip
              v-for="(selection, i) in selected"
              :key="i"
              dark
              small
              class="custom-color-accent ma-1"
              close
              close-icon="mdi-delete"
              @click:close="deletePermission(selection)"
            >{{ selection.name.split('.')[0]+' '+ selection.name.split('.')[1]}}</v-chip>
          </v-scroll-x-transition>

            <v-scroll-x-transition  v-else group hide-on-leave>
            <v-chip
              v-for="(selection, i) in selected"
              :key="i"
              dark
              small
              class="custom-color-accent ma-1"
            >{{ selection.name }}</v-chip>
          </v-scroll-x-transition>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import _ from "lodash";
export default {
  props: ["permissions", "selectedRole"],
  data() {
    return {
      isLoading: false,
      selected: [],
      allPermissions: []
    };
  },
  computed: {
    items() {
      const children = Object.entries(
        this.allPermissions
      ).map(([name, children]) => ({ name, children }));
      return children;
    }
  },
  watch: {
    selected() {
      if (this.selected.length > this.selectedRole.permissions.length) {
        // var max1=this.selected.length;
        // var i = this.selectedRole.permissions.length;
        /*   for(i;i<max1;i++){
           this.add(this.selected[i])
         }*/
        let permissionsFiltered = this.selected.filter(value =>!this.selectedRole.permissions.map(permission => permission.name).includes(value.name)).map(permission => permission.id);
        this.addMultiple(permissionsFiltered);

      } else if (this.selected.length < this.selectedRole.permissions.length) {
        let permissionsFiltered = this.selected.map(permission => permission.id);
        this.deleteMultiple(permissionsFiltered);
      }
    },
    selectedRole(val) {
      this.selected = [];
      Object.assign(this.selected, val.permissions);
      /* for (let i = 0; i <this.selected.length; i++) { 
        this.selected[i].subname=this.selected[i].name.split('.')[0]+' '+this.selected[i].name.split('.')[1]
      } */
    }
  },
  created() {
    Object.assign(this.selected, this.selectedRole.permissions);
    /* for (let i = 0; i <this.selected.length; i++) { 
        this.selected[i].subname=this.selected[i].name.split('.')[0]+' '+this.selected[i].name.split('.')[1]
    } */
    Object.assign(this.allPermissions, this.permissions);
    this.allPermissions = _.groupBy(this.allPermissions, function(item) {
      return item.name.split(".")[0];
    });
  },
  methods: {
    addMultiple(item) {
      this.isLoading = true;
      let payload = { role: this.selectedRole.id, permissions: item };
      this.assignRolePermissions(payload).then(() => {
        this.$root.$emit("callAlert", {
          text: "Permission Assigned",
          type: "success",
          alert: true
        });
        this.isLoading = false;
      });
    },
     deleteMultiple(item) {
      this.isLoading = true;
      let payload = { role: this.selectedRole.id, permissions: item };
      this.unassignRolePermissions(payload).then(() => {
        this.$root.$emit("callAlert", {
          text: "Permission Unassigned",
          type: "dark",
          alert: true
        });
        this.isLoading = false;
      });
    },

    deletePermission(item) {
      this.isLoading = true;
      let payload = { role: this.selectedRole.id, permission: item.id };
      this.unassignRolePermission(payload).then(() => {
        this.$root.$emit("callAlert", {
          text: "Permission Unassigned",
          type: "dark",
          alert: true
        });
        this.isLoading = false;
      });
    },
    //NOT USED
     add(item) {
      this.isLoading = true;
      let payload = { role: this.selectedRole.id, permission: item.id };
      this.assignRolePermission(payload).then(() => {
        this.$root.$emit("callAlert", {
          text: "Permission Assigned",
          type: "success",
          alert: true
        });
        this.isLoading = false;
      });
    },


    ...mapActions("role", ["assignRolePermission","unassignRolePermission","assignRolePermissions","unassignRolePermissions"]),
  }
};
</script>